<template>
    <div>
         <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6">
                    <div class="formgrid grid">
                        <div class="field col-12 md:col-6">
                            <label>Area</label>
                                <Dropdown id="provinsi_id" dataKey="provinsi_id" v-model="forms.provinsi_id" :loading="loadingDropdownProvinsi"
                                    :options="dataDropdownProvinsi" :class="{ 'p-invalid': errorAdd.provinsi_id }"
                                    optionLabel="provinsi_name" optionValue="provinsi_id" placeholder="Pilih Area"
                                    :filter="true" :showClear="true" @filter="searchDropdownProvinsi($event, 'add')"
                                    @change="changeKabupaten()" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.provinsi_id">{{
                                    errorAdd.provinsi_id[0]
                            }}</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label>Kota</label>
                            <Dropdown id="kabupaten_id" dataKey="kabupaten_id" v-model="forms.kabupaten_id" :loading="loadingDropdownKabupaten"
                                :options="dataDropdownKabupaten" :class="{ 'p-invalid': errorAdd.kabupaten_id }"
                                optionLabel="kabupaten_name" optionValue="kabupaten_id" placeholder="Pilih Kabupaten"
                                :filter="true" :showClear="true" @filter="searchDropdownKabupaten($event, 'add')"
                                @change="changeSubDist()" />
                            <small class="p-invalid" style="color: red" v-if="errorAdd.kabupaten_id">{{
                                errorAdd.kabupaten_id[0]
                            }}</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label>Sub Dist</label>
                            <Dropdown id="subdist_id" dataKey="subdist_id" v-model="forms.subdist_id" :loading="loadingDropdownSubdist"
                                :options="dataDropdownSubdist" :class="{ 'p-invalid': errorAdd.subdist_id }"
                                optionLabel="label" optionValue="subdist_id" placeholder="Pilih Sub Dist" :filter="true"
                                :showClear="true" @filter="searchDropdownSubDist($event, 'add')"
                                @change="changeSalesman()" />
                            <small class="p-invalid" style="color: red" v-if="errorAdd.subdist_id">{{
                                errorAdd.subdist_id[0]
                            }}</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label>Salesman</label>
                            <Dropdown id="salesman_id" dataKey="salesman_id" v-model="forms.salesman_id" :loading="loadingDropdownSalesman"
                                :options="dataDropdownSalesman" :class="{ 'p-invalid': errorAdd.salesman_id }"
                                optionLabel="label" optionValue="salesman_id" placeholder="Pilih Salesman" :filter="true"
                                :showClear="true" @filter="searchDropdownSalesman($event, 'add')" @change="changeBeat()"/>
                            <small class="p-invalid" style="color: red" v-if="errorAdd.salesman_id">{{
                                errorAdd.salesman_id[0]
                            }}</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label>Beat</label>
                            <Dropdown id="beat_id" dataKey="beat_id" v-model="forms.beat_id" :loading="loadingDropdownBeat"
                                :options="dataDropdownBeat" :class="{ 'p-invalid': errorAdd.beat_id }"
                                optionLabel="beat_name" optionValue="beat_id" placeholder="Pilih Beat" :filter="true"
                                :showClear="true" @filter="searchDropdownBeat($event, 'add')" @change="changeOutlet()"/>
                            <small class="p-invalid" style="color: red" v-if="errorAdd.beat_id">{{
                                errorAdd.beat_id[0]
                            }}</small>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field col-12 md:col-6">
                            <label>Hari</label>
                            <Dropdown v-model="forms.hari" :options="dataDropdownHari" optionLabel="name" optionValue="name" placeholder="Pilih Hari" :class="{ 'p-invalid': errorAdd.hari }" 
                                @change="changeStartDate()"/>
                             <small class="p-invalid" style="color: red" v-if="errorAdd.hari">{{
                                errorAdd.hari[0]
                            }}</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label>Frekuensi</label>
                            <Dropdown v-model="forms.frekuensi" :options="dataDropdownFrekuensi" optionLabel="name" optionValue="code" placeholder="Pilih Frekuensi" :class="{ 'p-invalid': errorAdd.frekuensi }" />
                             <small class="p-invalid" style="color: red" v-if="errorAdd.frekuensi">{{
                                errorAdd.frekuensi[0]
                            }}</small>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field col-12 md:col-6">
                            <label>Tanggal Mulai</label>
                            <Dropdown id="start_date" v-model="forms.start_date" :loading="loadingDropdownStartDate"
                                :options="dataDropdownStartDate" :class="{ 'p-invalid': errorAdd.start_date }"
                                optionLabel="calendar_date" optionValue="calendar_date" placeholder="Pilih Tanggal Mulai" :filter="true"
                                :showClear="true" @filter="searchDropdownStartDate($event, 'add')" @change="changePreview()"/>
                            <small class="p-invalid" style="color: red" v-if="errorAdd.start_date">{{
                                errorAdd.start_date[0]
                            }}</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label>Tanggal Berakhir</label>
                            <Calendar :manualInput="false" v-model="end_date" dateFormat="yy-mm-dd" icon="pi pi-calendar" :showIcon="true" :class="{ 'p-invalid': errorAdd.tanggal_akhir }" @change="changePreview()"/>
                            <small class="p-invalid" style="color: red" v-if="errorAdd.tanggal_akhir">{{
                                errorAdd.tanggal_akhir[0]
                            }}</small>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <div class="card" style="height: calc(63vh - 143px)">
                        <DataTable :loading="loadingOutlet" :value="outlet" :scrollable="true" scrollHeight="flex">
                            <Column field="outlet_name" header="Nama Outlet">
                                <template #body="slotProps">
                                    {{ slotProps.data.outlet_name }}
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>
         </div>

        <Button :loading="loadingPreview" label="Preview" class="p-button-info" @click="preview"/>

        <Message severity="info" :closable="false" v-if="previewShow">
            <div class="text-xl">
                <div v-if="this.isOption">Call Plan dari Beat ini sudah ada pada database. Silahkan pilih option dibawah ini untuk melanjutkan</div>
                <div v-else>Call Plan dari Beat ini belum tersedia. Silahkan lanjutkan untuk menyimpan.</div>
            </div>
        </Message>

        <div class="p-fluid" v-if="this.isOption">
            <div class="formgrid grid">
                <div class="field col-12 md:col-3">
                    <label>Option</label>
                    <Dropdown v-model="forms.status_delete" :options="dataDropdownOption" optionLabel="name" optionValue="code" placeholder="Pilih Option" :class="{ 'p-invalid': errorAdd.status_delete }" />
                        <small class="p-invalid" style="color: red" v-if="errorAdd.status_delete">{{
                        errorAdd.hari[0]
                    }}</small>
                </div>
            </div>
        </div>

        <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" v-if="previewShow"/>
    </div>
</template>

<script>

import moment from 'moment';
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingPreview: false,
            loadingOutlet: false,
            loadingDropdownProvinsi: false,
            loadingDropdownKabupaten: false,
            loadingDropdownSubdist: false,
            loadingDropdownKecamatan: false,
            loadingDropdownKelurahan: false,
            loadingDropdownSalesman: false,
            loadingDropdownStartDate: false,
            loadingDropdownBeat: false,

            // dataDropdown
            dataDropdownProvinsi: null,
            dataDropdownKabupaten: null,
            dataDropdownSubdist: null,
            dataDropdownKecamatan: null,
            dataDropdownKelurahan: null,
            dataDropdownSalesman: null,
            dataDropdownStartDate: null,
            dataDropdownBeat: null,
            dataDropdownHari: [
                {name: 'SENIN'},
                {name: 'SELASA'},
                {name: 'RABU'},
                {name: 'KAMIS'},
                {name: 'JUMAT'},
                {name: 'SABTU'},
            ],
            dataDropdownFrekuensi: [
                {name: 'Weekly', code: 'weekly'},
                {name: 'Biweekly', code: 'biweekly'},
                {name: 'Monthly', code: 'monthly'},
            ],
            dataDropdownOption: [
                {name: 'Teruskan dan Simpan', code: false},
                {name: 'Hapus dan Simpan', code: true},
            ],

            end_date: new Date(),
            // addNew
            forms: {
                salesman_id: null,
                provinsi_id: null,
                kabupaten_id: null,
                subdist_id: null,
                hari: 'SENIN',
                frekuensi: 'weekly',
                start_date: null,
                beat_id: null,
                status_delete: false,
            },

            outlet: null,
            previewShow: false,
            isExist: 0,
            isOption: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownProvinsi('');
        this.searchDropdownStartDate('');

    },
    computed: {
        ...mapGetters(['errorAdd']),
        end_date_label(){ 
            return moment(this.end_date, 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DROPDOWN
        searchDropdownProvinsi(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownProvinsi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/area',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownProvinsi = res.data.data;
                            this.loadingDropdownProvinsi = false;
                        } else if (purpose == null) {
                            this.dataDropdownProvinsi = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeKabupaten() {
            this.forms.kabupaten_id = null;
            this.dataDropdownKabupaten = null;
            this.searchDropdownKabupaten('');

            this.forms.subdist_id = null;
            this.dataDropdownSubdist = null;
            this.searchDropdownSubDist('');

            this.forms.salesman_id = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');

            this.forms.beat_id = null;
            this.dataDropdownBeat = null;
            this.searchDropdownBeat('');

            this.outlet = null;
            this.getOutlet('');

            this.changePreview();
        },
        searchDropdownKabupaten(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.provinsi_id) {

                    if (valueEdit) {
                        this.$refs.dkab.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownKabupaten = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kota',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.forms.provinsi_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownKabupaten = res.data.data;
                                this.loadingDropdownKabupaten = false;
                            } else if (purpose == null) {
                                this.dataDropdownKabupaten = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeSubDist() {
            this.forms.subdist_id = null;
            this.dataDropdownSubdist = null;
            this.searchDropdownSubDist('');

            this.forms.salesman_id = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');

            this.forms.beat_id = null;
            this.dataDropdownBeat = null;
            this.searchDropdownBeat('');

            this.outlet = null;
            this.getOutlet('');

            this.changePreview();
        },
        searchDropdownSubDist(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.provinsi_id && this.forms.kabupaten_id) {

                    if (valueEdit) {
                        this.$refs.dsubdist.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownSubdist = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/subdist',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.forms.provinsi_id,
                            "kabupaten_id": this.forms.kabupaten_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownSubdist = res.data.data;
                                this.loadingDropdownSubdist = false;
                            } else if (purpose == null) {
                                this.dataDropdownSubdist = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeSalesman() {
            this.forms.salesman_id = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');

            this.forms.beat_id = null;
            this.dataDropdownBeat = null;
            this.searchDropdownBeat('');

            this.outlet = null;
            this.getOutlet('');

            this.changePreview();
        },
        searchDropdownSalesman(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.provinsi_id && this.forms.kabupaten_id && this.forms.subdist_id) {

                    if (valueEdit) {
                        this.$refs.dsalesman.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownSalesman = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/salesman',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.forms.provinsi_id,
                            "kabupaten_id": this.forms.kabupaten_id,
                            "subdist_id": this.forms.subdist_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownSalesman = res.data.data;
                                this.loadingDropdownSalesman = false;
                            } else if (purpose == null) {
                                this.dataDropdownSalesman = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeBeat() {
            this.forms.beat_id = null;
            this.dataDropdownBeat = null;
            this.searchDropdownBeat('');

            this.outlet = null;
            this.getOutlet('');

            this.changePreview();
        },
        searchDropdownBeat(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.provinsi_id && this.forms.kabupaten_id && this.forms.subdist_id && this.forms.salesman_id) {

                    if (valueEdit) {
                        this.$refs.dbeat.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownBeat = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/beat',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "salesman_id": this.forms.salesman_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownBeat = res.data.data;
                                this.loadingDropdownBeat = false;
                            } else if (purpose == null) {
                                this.dataDropdownBeat = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeOutlet() {
            this.outlet = null;
            this.getOutlet('');

            this.changePreview();
        },
        getOutlet() {
            setTimeout(() => {
                if (this.forms.beat_id) {
                    this.loadingOutlet = true;

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/beat',
                        params: {
                            "beat_id" : this.forms.beat_id,
                        }
                    })
                    .then(res => {
                        this.loadingOutlet = false;
                        this.$store.commit('setErrors', {});
                        this.outlet = res.data.data[0].vmaster006beatoutlet;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loadingOutlet = false;
                    });
              }
            }, 250);
        },
        changeStartDate() {
            this.forms.start_date = null;
            this.dataDropdownStartDate = null;
            this.searchDropdownStartDate('');

            this.changePreview();
        },
        searchDropdownStartDate(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.hari) {

                    if (valueEdit) {
                        this.$refs.dsalesman.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownStartDate = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/callplan/tgl-mulai',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "hari": this.forms.hari,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownStartDate = res.data.data;
                                this.loadingDropdownStartDate = false;
                            } else if (purpose == null) {
                                this.dataDropdownStartDate = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changePreview() {
            this.previewShow = false;
            this.isOption = false;
            this.isExist= 0;
        },
        preview(){
            if(this.forms.beat_id && this.forms.start_date && this.end_date_label){
                
                this.loadingPreview = true;

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/callplan/check',
                    params: {
                        "beat_id" : this.forms.beat_id,
                        "tanggal_mulai" : this.forms.start_date,
                        "tanggal_akhir" : this.end_date_label,
                    }
                })
                .then(res => {

                    this.$store.commit('setErrorAdd', {});
                    this.isExist = res.data.data;

                    if(this.isExist > 0){
                        this.isOption = true;
                    }

                    this.previewShow = true;
                    this.loadingPreview = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('setErrorAdd', err.response.data.data);
                    this.loadingPreview = false;
                });

            }else{
                alert("silahkan isi field yang ada terlebih dahulu");
            }
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/callplan/create',
                data: {
                    "beat_id": this.forms.beat_id,
                    "salesman_id": this.forms.salesman_id,
                    "hari": this.forms.hari,
                    "frekuensi": this.forms.frekuensi,
                    "tanggal_mulai": this.forms.start_date,
                    "tanggal_akhir": this.end_date_label,
                    "status_delete": this.forms.status_delete,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.salesman_id = null;
            this.forms.provinsi_id = null;
            this.forms.kabupaten_id = null;
            this.forms.subdist_id = null;
            this.forms.hari = 'SENIN';
            this.forms.frekuensi = 'weekly';
            this.forms.start_date = null;
            this.forms.beat_id = null;
            this.forms.status_delete = false;


            this.dataDropdownKabupaten = null;
            this.dataDropdownSubdist = null;
            this.dataDropdownSalesman = null;
            this.dataDropdownBeat = null;


            this.end_date= new Date();
            this.outlet= null;
            this.previewShow= false;
            this.isExist= 0;
            this.isOption= false;

             this.searchDropdownStartDate('');
        },
    }
}
</script>
